// 申遗承诺
import SHZT from './sn-table-column-shzt';
import DJZT from './sn-table-column-djzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: 'XFXTMC',
                    label: '消防系统名称',
                    minWidth: 135,
                }, {
                    prop: 'YTFL',
                    label: '用途分类'
                }, {
                    prop: 'LJ',
                    label: '附件',
                    showFileBtn: true,
                },
                SHZT,
                DJZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: [{
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnSee',
                    title: '查看',
                    icon: 'el-icon-view',
                    backColor: 'transparent',
                    color: '#20a0ff'
                }]
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [{
            btnType: 'button',
            operateType: 'buttonExport',
            showMethod: 'showExportBtn',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export default selfData;